<template>
    <div class="container-action-input">
      <button class="button-action-input"
      @keyup.esc="closeInput()"
      @click="openInput()"
      @keyup.enter.prevent="$emit('do-something', textValue)">
      <input v-if="openSearch"
        @focusout="closeInput($event)"
        type="text"
        ref="action-input"
        class="action-input"
        v-model="textValue">
        <div v-if="showActionButton">
          <i v-if="!isLoading"
          class="material-icons action-input-icon"
          @click="$emit('do-something', textValue)">{{ icon }}</i>
          <div v-show="isLoading" class="loading-action-input">
              <Circle2 class="circle"></Circle2>
          </div>
        </div>
        <app-heading  v-if="!openSearch"
          level="h7"
          class="title-action-input">
          {{ placeHolderText }}
        </app-heading>
      </button>
    </div>
</template>
<script>
export default {
  name: 'InputWithAction',
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  props: {
    placeHolderText: {
      type: String,
      default: ''
    },
    showMessage: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    showActionButton: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openSearch: false,
      textValue: ''
    }
  },
  watch: {
    isLoading (val, valOld) {
      if (!val && this.openSearch) {
        this.textValue = ''
        if (this.$refs['action-input']) {
          this.$refs['action-input'].focus()
        }
      }

      if (valOld && !valOld) this.closeInput()
    }
  },
  methods: {
    openInput () {
      if (this.openSearch) {
        if (!this.textValue) {
          return this.closeInput()
        }
        return false
      }
      this.textValue = ''
      this.openSearch = true
      setTimeout(() => {
        if (this.$refs['action-input']) {
          this.$refs['action-input'].focus()
          this.$refs['action-input'].style.width = '100%'
        }
      }, 10)
    },
    clenSearch () {
      this.messageError = this.$i18n.t('bvs.search_not_found')
      this.textValue = ''
      setTimeout(() => {
        this.messageError = ''
      }, 3000)
    },
    closeInput (evt) {
      if (evt && this.textValue) {
        return false
      }
      if (this.$refs['action-input']) {
        this.$refs['action-input'].style.width = '10px'
      }
      setTimeout(() => {
        this.openSearch = false
      }, 250)
    }
  }
}
</script>

<style lang="scss">
.container-action-input{
  width: 100%;
  .button-action-input {
    position: relative;
    min-width: 100%;
    height: 45px;
    background: #F0F2F5;
    color: var(--accent-color);
    border: 1px solid #cacaca;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
    .action-input-icon {
      width: 1.7em;
      height: 1.7em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-action-input {
      font-weight: 100 !important;
      font-size: 1.1em;
      color: #464649 !important;
    }
    .action-input {
      width: 0px;
      height: 35px;
      background: #b3b3b324;
      border: none;
      padding-left: 5px;
      transition: width 0.6s;
      color: #464649;
    }
    .action-input::placeholder {
      color: #464649;
    }
    .message-action-input {
      position: absolute;
      bottom: -35px;
      width: 250px;
      font-size: 13px !important;
    }
    .loading-action-input {
      padding: 0.5em;
      width: 3em;
      display: flex;
      justify-content: center;
      & .circle {
        width: 20px !important;
        height: 20px !important;
        border-color: #f0f2f5 #454548 #454548 !important;
        border-width: 2px !important;
      }
    }
  }
}
</style>
